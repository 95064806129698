<template>
  <div class="mainslider">
    <div v-if="activeSlide !== null" class="slide">
      <div :style="`animation:1s toLeft;background-image: url(/static_files/mainslider_bg/${slides[isKids][activeSlide].kinopoisk_id}.webp)`" class="bg" />
      <div class="wrapper">
        <div class="poster">
          <nuxt-link :to="`/film/${
            postUrl(slides[isKids][activeSlide].kinopoisk_id, slides[isKids][activeSlide].title_ru)
          }`">
            <img :src="filmPoster(slides[isKids][activeSlide].kinopoisk_id)" :alt="slides[isKids][activeSlide].title_ru">
          </nuxt-link>
        </div>
        <div class="meta">
          <nuxt-link :to="`/film?year=${slides[isKids][activeSlide].year}`">
            {{ slides[isKids][activeSlide].year }}
          </nuxt-link>
          <nuxt-link
            v-for="country in slides[isKids][activeSlide].countries"
            :key="`slide-country-${country}`"
            :to="`/film?country=${country}`"
          >
            {{ country }}
          </nuxt-link>
          <nuxt-link
            v-for="genre in slides[isKids][activeSlide].genres"
            :key="`slide-genre-${genre}`"
            :to="`/film?genre=${genre}`"
          >
            {{ genre }}
          </nuxt-link>
          <span v-if="slides[isKids][activeSlide].age">
            {{ slides[isKids][activeSlide].age }}
          </span>
        </div>
        <div v-if="isDesktop" class="tagline">
          {{ slides[isKids][activeSlide].tagline }}
        </div>
        <div class="actions">
          <nuxt-link :to="`/film/${postUrl(slides[isKids][activeSlide].kinopoisk_id, slides[isKids][activeSlide].title_ru)}`" tag="button" class="watch">
            <i class="icon-watch" />
            Смотреть бесплатно
          </nuxt-link>
          <div class="second">
            <button v-if="slides[isKids][activeSlide].trailer" @click="showTrailer" class="trailer">
              <i class="icon-trailer" />
              Трейлер
            </button>
            <div v-else />
            <FavsComponent :payload="{
              film: {
                title_ru: slides[isKids][activeSlide].title_ru, title_en: slides[isKids][activeSlide].title_en,
                poster: slides[isKids][activeSlide].poster, kinopoisk_id: slides[isKids][activeSlide].kinopoisk_id
              },
              button: true
            }" />
          </div>
        </div>
      </div>
    </div>
    <div class="switchers">
      <i @click="changeSlide('prev')" class="prev" />
      <div
        v-for="i in slides[isKids].length"
        :key="`switcher-${i}`"
        :class="{ active: (i - 1) === activeSlide }"
        class="item"
      >
        <span />
      </div>
      <i @click="changeSlide('next')" class="next" />
    </div>

    <div v-if="showModal" class="modal">
      <div class="actions">
        <i @click="closeTrailer" class="icon-close" />
      </div>
      <iframe :src="slides[isKids][activeSlide].trailer" allowfullscreen />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  components: {
    FavsComponent: () => import('~/components/FavsComponent.vue')
  },
  watch: {
    isKids() {
      this.startIntervals()
    }
  },
  computed: {
    ...mapState(['isDesktop']),
    isKids() {
      return this.$store.state.isKids ? 'kids' : 'all'
    }
  },
  data() {
    return {
      slides: {
        all: [
          {
            title_ru: 'Дэдпул и Росомаха',
            year: 2024,
            countries: ['США'],
            genres: ['фантастика'],
            tagline: 'Уэйд Уилсон попадает в организацию «Управление временными изменениями», что вынуждает его вернуться к своему альтер-эго Дэдпулу и изменить историю с помощью Росомахи.',
            kinopoisk_id: '1008444',
            age: 'R',
            trailer: 'https://finve-as.allarknow.online/t/?token_movie=8518b7bcfb0e99a215e671b889b29a&token=a7b8af4347ca73314bfe2ce7db6cc5'
          },
          {
            title_ru: 'Ворон',
            year: 2024,
            countries: ['Великобритания', 'Франция', 'США'],
            genres: ['Ужасы', 'Фэнтези', 'Боевик', 'Триллер', 'Мелодрама', 'Криминал'],
            tagline: 'Пожертвовав собой, чтобы спасти возлюбленную, Эрик Дрэйвен застревает между мирами живых и мёртвых. Вернувшись с того света, он не остановится ни перед чем, чтобы свести счёты с убийцами. Отныне он Ворон, жаждущий справедливости, и его месть будет жестока как никогда.',
            kinopoisk_id: '441406',
            age: '18+',
            trailer: 'https://finve-as.allarknow.online/t/?token_movie=3a0fbdf0004047453176863047337e&token=a528db469ce8c924a8d7eef1717db8'
          },
          {
            title_ru: 'Плохие парни до конца',
            year: 2024,
            countries: ['США'],
            genres: ['Боевик', 'Комедия', 'Криминал'],
            tagline: 'Полицейские Майами Маркус Бернетт и Майк Лоури узнают из теленовостей, что их погибшего капитана полиции Конрада Говарда обвиняют в коррупции и связях с наркокартелями. Маркус и Майк отказываются в это верить, а вскоре получают видеозапись от самого Говарда, снятую им перед смертью, в которой он просит найти своих убийц и восстановить его честное имя. Сделать это не так просто, теперь охота объявлена на самих Бернетта и Лоури. Парни вынуждены пуститься в бега.',
            kinopoisk_id: '927494',
            age: '18+',
            trailer: 'https://finve-as.allarknow.online/t/?token_movie=154ff99ea2a012dbe0c53d3e9ecf2a&token=a528db469ce8c924a8d7eef1717db8'
          },
          // {
          //   title_ru: 'Красное уведомление',
          //   year: 2021,
          //   countries: ['США'],
          //   genres: ['боевик', 'комедия', 'триллер'],
          //   tagline: 'Агент Интерпола отправляется на поиски самого знаменитого вора в мире.',
          //   kinopoisk_id: '1115099',
          //   age: '18+',
          //   trailer: 'https://finve.allohastream.com/t/?token_movie=0c69948df6eadf95bc8e8a49f34302&token=6ce155548803a6dcd86ccc028ae7a2'
          // },
        ],
        kids: [
          {
            title_ru: 'Фиксики',
            year: 2010,
            countries: ['Россия'],
            genres: ['детский', 'мультфильм'],
            tagline: '',
            kinopoisk_id: '581653',
            age: '',
            trailer: ''
          },
          {
            title_ru: 'Три кота',
            year: 2016,
            countries: ['Россия'],
            genres: ['детский', 'мультфильм', 'приключения'],
            tagline: '',
            kinopoisk_id: '935898',
            age: '',
            trailer: ''
          },
          {
            title_ru: 'Сказочный патруль',
            year: 2016,
            countries: ['Россия'],
            genres: ['детский', 'мультфильм'],
            tagline: 'Волшебники среди нас',
            kinopoisk_id: '983091',
            age: '',
            trailer: ''
          },
          {
            title_ru: 'Лео и Тиг',
            year: 2016,
            countries: ['Россия'],
            genres: ['детский', 'мультфильм', 'приключения'],
            tagline: 'Лесные приключения маленьких друзей',
            kinopoisk_id: '1045867',
            age: '',
            trailer: ''
          }
        ],
      },
      activeSlide: 0,
      spanI: 0,
      intervalSlider: null,
      intervalSliderSpan: null,
      showModal: false
    }
  },
  mounted() {
    this.startIntervals()
  },
  beforeDestroy() {
    clearInterval(this.intervalSlider)
    clearInterval(this.intervalSliderSpan)
  },
  methods: {
    startIntervals() {
      this.activeSlide = 0
      this.spanI = 0
      clearInterval(this.intervalSlider)
      clearInterval(this.intervalSliderSpan)
      this.intervalSlider = null
      this.intervalSliderSpan = null

      this.intervalSliderSpan = setInterval(() => {
        const span = document.querySelector('.mainslider .switchers .item.active span')
        if (span) {
          ++this.spanI
          span.style.width = `${this.spanI * 10}%`
        }
      }, 1000)

      this.intervalSlider = setInterval(() => {
        let aS = this.activeSlide
        this.activeSlide = null
        this.$nextTick(() => {
          this.spanI = 0
          if (aS === this.slides[this.isKids].length - 1) {
            this.activeSlide = 0
          } else {
            ++aS
            this.activeSlide = aS
          }
        })
      }, 10 * 1000)
    },
    closeTrailer() {
      this.showModal = false
      document.body.style.overflow = ''
    },
    showTrailer() {
      clearInterval(this.intervalSlider)
      clearInterval(this.intervalSliderSpan)
      this.showModal = true
      document.body.style.overflow = 'hidden'
    },
    changeSlide(op) {
      clearInterval(this.intervalSlider)
      clearInterval(this.intervalSliderSpan)
      let aS = this.activeSlide
      this.activeSlide = null
      this.$nextTick(() => {
        this.spanI = 0
        if (op === 'prev') {
          if (aS === 0) {
            this.activeSlide = this.slides[this.isKids].length - 1
          } else {
            --aS
            this.activeSlide = aS
          }
        } else if (op === 'next') {
          if (aS === this.slides[this.isKids].length - 1) {
            this.activeSlide = 0
          } else {
            ++aS
            this.activeSlide = aS
          }
        }
      })
    }
  }
}
</script>

<style src="~/assets/slider.main.styl" lang="stylus" />
