var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mainslider"},[(_vm.activeSlide !== null)?_c('div',{staticClass:"slide"},[_c('div',{staticClass:"bg",style:(`animation:1s toLeft;background-image: url(/static_files/mainslider_bg/${_vm.slides[_vm.isKids][_vm.activeSlide].kinopoisk_id}.webp)`)}),_vm._v(" "),_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"poster"},[_c('nuxt-link',{attrs:{"to":`/film/${
          _vm.postUrl(_vm.slides[_vm.isKids][_vm.activeSlide].kinopoisk_id, _vm.slides[_vm.isKids][_vm.activeSlide].title_ru)
        }`}},[_c('img',{attrs:{"src":_vm.filmPoster(_vm.slides[_vm.isKids][_vm.activeSlide].kinopoisk_id),"alt":_vm.slides[_vm.isKids][_vm.activeSlide].title_ru}})])],1),_vm._v(" "),_c('div',{staticClass:"meta"},[_c('nuxt-link',{attrs:{"to":`/film?year=${_vm.slides[_vm.isKids][_vm.activeSlide].year}`}},[_vm._v("\n          "+_vm._s(_vm.slides[_vm.isKids][_vm.activeSlide].year)+"\n        ")]),_vm._v(" "),_vm._l((_vm.slides[_vm.isKids][_vm.activeSlide].countries),function(country){return _c('nuxt-link',{key:`slide-country-${country}`,attrs:{"to":`/film?country=${country}`}},[_vm._v("\n          "+_vm._s(country)+"\n        ")])}),_vm._v(" "),_vm._l((_vm.slides[_vm.isKids][_vm.activeSlide].genres),function(genre){return _c('nuxt-link',{key:`slide-genre-${genre}`,attrs:{"to":`/film?genre=${genre}`}},[_vm._v("\n          "+_vm._s(genre)+"\n        ")])}),_vm._v(" "),(_vm.slides[_vm.isKids][_vm.activeSlide].age)?_c('span',[_vm._v("\n          "+_vm._s(_vm.slides[_vm.isKids][_vm.activeSlide].age)+"\n        ")]):_vm._e()],2),_vm._v(" "),(_vm.isDesktop)?_c('div',{staticClass:"tagline"},[_vm._v("\n        "+_vm._s(_vm.slides[_vm.isKids][_vm.activeSlide].tagline)+"\n      ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"actions"},[_c('nuxt-link',{staticClass:"watch",attrs:{"to":`/film/${_vm.postUrl(_vm.slides[_vm.isKids][_vm.activeSlide].kinopoisk_id, _vm.slides[_vm.isKids][_vm.activeSlide].title_ru)}`,"tag":"button"}},[_c('i',{staticClass:"icon-watch"}),_vm._v("\n          Смотреть бесплатно\n        ")]),_vm._v(" "),_c('div',{staticClass:"second"},[(_vm.slides[_vm.isKids][_vm.activeSlide].trailer)?_c('button',{staticClass:"trailer",on:{"click":_vm.showTrailer}},[_c('i',{staticClass:"icon-trailer"}),_vm._v("\n            Трейлер\n          ")]):_c('div'),_vm._v(" "),_c('FavsComponent',{attrs:{"payload":{
            film: {
              title_ru: _vm.slides[_vm.isKids][_vm.activeSlide].title_ru, title_en: _vm.slides[_vm.isKids][_vm.activeSlide].title_en,
              poster: _vm.slides[_vm.isKids][_vm.activeSlide].poster, kinopoisk_id: _vm.slides[_vm.isKids][_vm.activeSlide].kinopoisk_id
            },
            button: true
          }}})],1)],1)])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"switchers"},[_c('i',{staticClass:"prev",on:{"click":function($event){return _vm.changeSlide('prev')}}}),_vm._v(" "),_vm._l((_vm.slides[_vm.isKids].length),function(i){return _c('div',{key:`switcher-${i}`,staticClass:"item",class:{ active: (i - 1) === _vm.activeSlide }},[_c('span')])}),_vm._v(" "),_c('i',{staticClass:"next",on:{"click":function($event){return _vm.changeSlide('next')}}})],2),_vm._v(" "),(_vm.showModal)?_c('div',{staticClass:"modal"},[_c('div',{staticClass:"actions"},[_c('i',{staticClass:"icon-close",on:{"click":_vm.closeTrailer}})]),_vm._v(" "),_c('iframe',{attrs:{"src":_vm.slides[_vm.isKids][_vm.activeSlide].trailer,"allowfullscreen":""}})]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }